import {FetchOptions, ofetch} from "ofetch";

import {getSdk, type Requester} from "./__generated__";
import Bugsnag from "@bugsnag/js";

const graphqlEndpoint = process.env.NEXT_PUBLIC_APOLLO_URL as string;

export const fetchRequester: Requester = async <TResult, TVariables>(
  doc: string,
  variables: TVariables,
  options?: RequestInit,
): Promise<TResult> => {
  const headers = {
    "Content-Type": "application/json",
  } satisfies FetchOptions["headers"];

  const res: {
    data: TResult;
    errors: object;
  } = await ofetch(graphqlEndpoint, {
    method: "POST",
    headers,
    body: {
      query: doc,
      variables,
      errorPolicy: "all",
    },
    next: {revalidate: 86_400},
    retry: 0,
    ...options,
  });

  if (!res.data) {
    throw new Error("No data returned from fetchRequester");
  }

  if (res.errors) {
    if (process.env.NEXT_PUBLIC_BUGSNAG_API_KEY) {
      Bugsnag.notify(new Error('GraphQL Error'), (event) => {
        event.severity = 'error';
        event.unhandled = false;
        event.addMetadata('error', res.errors)
      })
    }

    console.error('GraphQL Error:', res.errors);
  }

  return res.data;
};

export const SDK = getSdk(fetchRequester);

import {AllowedLanguages, Translation} from "@/types/generic";
import {getOfficePage} from "@/graphql";
import {applyPaths, officePaths} from "routes";
import {languages} from "@/i18n/settings";

export function slugify(str: string) {
  return String(str)
    .normalize("NFKD") // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
    .replace(/\s+/g, "-") // replace spaces with hyphens
    .replace(/-+/g, "-"); // remove consecutive hyphens
}

export function generateSlug(title: string, id: string) {
  return `${slugify(title)}-${id}`;
}

export function generateJobUrl(
  locale: AllowedLanguages,
  title: string,
  id: string,
) {
  return `/${locale}/jobs/${generateSlug(title, id)}`;
}

export function generateJobOverviewUrl(
    locale: AllowedLanguages,
) {
  return `/${locale}/jobs/`;
}

export function generateJobApplyUrl(
  locale: AllowedLanguages,
  id: string,
) {
  return `/${locale}/${applyPaths[locale]}?jobId=${id}`;
}

export function generateOfficeUrl(
  locale: AllowedLanguages,
  title: string,
  id: string,
) {
  return `/${locale}/${officePaths[locale]}/${generateSlug(title, id)}`;
}

export async function generateLocalizedOfficeUrl(
  locale: AllowedLanguages,
  id: string,
) {

  const office = await getOfficePage({
    officeId: id,
    langcode: locale,
  });
  if(! office.office) {
    return "";
  }

  return `/${locale}/${officePaths[locale]}/${generateSlug(office.office.title, id)}`;
}

export function getIdFromSlug(slug: string) {
  return slug.split("-").pop() ?? "";
}

interface PathsObj {
  en: string;
  fr: string;
  nl: string;
}

export function generateTranslatedPathsArray(paths: PathsObj | null, fixedPath?: string | null): Translation[] {
  if(! paths) {
    // Path after locale is the same for all languages
    return languages.map((lang) => {
      return {
        language: lang,
        url:  `/${lang}/${fixedPath}`,
      };
    });
  }

  return languages.map((lang) => {
    return {
      language: lang,
      url: fixedPath ? `/${lang}/${fixedPath}/${paths[lang]}` : `/${lang}/${paths[lang]}`,
    };
  });
}